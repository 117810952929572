.LoginContainer {
    background-color: white;
    border-radius: 20px;
    width: 70%;
    left: 15%;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    -webkit-transform: translate(0%, 50%);
    transform: translate(0%, 50%);

    box-shadow: 3px 3px 5px rgba(207, 207, 207, 0.65);
}

.LoginContainer h1 {
    font-weight: bold;
    font-size: 18px;
}

.LoginForm > * {
    display: block;
    width: 100%;
    padding: 0;
}

.LoginForm > label {
    color: gray;
    font-weight: bold;
    font-size: 14px;
}

.LoginForm > input:not(.LoginValidateButton) {
    height: 35px;
    border: none;
    background-color: #E5E5E5;
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    padding-left: 5px;
    box-sizing: border-box;
}

.LoginValidateButton {
    background-color: #4185F4;
    height: 56px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border: none;
}

.LoginSpinnerContent{
    margin-top: 10px;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
}

.LoginSpinnerContainer{
    background-color: #4185F4;
    height: 56px;
    border-radius: 3px;
    text-align: center;
}


@media (min-width: 1024px) {
    .LoginContainer {
        width: 40%;
        left: 30%;
        padding: 50px;
        box-sizing: border-box;
        position: relative;
        -webkit-transform: translate(0%, 50%);
        transform: translate(0%, 50%);
    }
}

.LoginForgottenPasswordContainer {
    width: 100%;
    text-align: center;
}

.LoginForgottenPasswordContainer > a, .LoginForgottenPasswordContainer > label{
    color: rgb(39, 111, 245);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.ForgotPasswordLabel {
    margin-top: 25px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 19px;
    width: 100%;
    text-align: center;
    display: block;
}