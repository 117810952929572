@value colors: "../../globalcss/colors.module.css";
@value v-header-background-color, v-menu-selected-background-color from colors;

@value sizes: "../../globalcss/sizes.module.css";
@value v-header-height from sizes;


.HeaderContainer {
    width: 100%;
    height: v-header-height;
    background-color: v-header-background-color;
    position: fixed;
    left: 200px;
    top: 0;
    z-index: 180;
    border-bottom: 1px solid rgb(217, 217, 217)
}

.HeaderLogo {
    top: calc(50% - 10px);
    left: 20px;
    position: relative;
    height: 15px;
    cursor: pointer;

}

.HeaderLogoutButton, .HeaderProfileButton {
    height: calc(v-header-height / 3);
}

.HeaderProfileContent > label:first-of-type {
    margin-left: 13px;
    margin-top: 2px;
    position: absolute;
}

.HeaderProfileContainer, .HeaderLogoutContainer, .HeaderSettingsContainer {
    cursor: pointer;
    position: fixed;
    height: calc(v-header-height - 20px);
    top: calc(10px);
    text-align: center;
}



.HeaderLogoutContainer:hover, .HeaderSettingsContainer:hover{
    background-color: #ebebeb;
}

.HeaderProfileContent > * {
    cursor: pointer;
}

.HeaderProfileContent {
    padding: 7px;
    margin-top: -4px;
    border-radius: 12px;
}

.HeaderProfileContent:hover {
    background-color: v-menu-selected-background-color;
}


.HeaderLogoutContent > * , .HeaderSettingsContent > * {
    cursor: pointer;
}

.HeaderProfileContainer {
    right: 45px;
    font-size: 14px;
}


.HeaderLogoutContainer, .HeaderSettingsContainer {
    background-color: #ffffff;
    border: 1px solid #bebebe;
    right: 3px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-top: 1px solid white;
    width: 160px;
}

.HeaderSettingsContainer {
    top: calc(v-header-height - 1px);
}

.HeaderLogoutContainer {
    top: calc(v-header-height - 1px);

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.HeaderSettingsContent > label > a {
    text-decoration: none;
    color: #575759;
}

.HeaderSettingsContainer > label, .HeaderLogoutContainer > label, .HeaderProfileContainer > label {
    position: fixed;
    top: 30px;
    right: 30px;
    cursor: pointer;
}
