@value sizes: "../globalcss/sizes.module.css";
@value v-header-height, v-menu-width from sizes;

.CasksContainer {
    padding: 10px;
}

.CasksContainer > table{
    width: 100%;
    border-collapse: collapse;
}

.CasksContainer > table > thead > tr > th:first-of-type, .CasksContainer > table > tbody > tr > td:first-of-type {
    border-left: none;
}

.CasksContainer > table > thead > tr > th:last-of-type, .CasksContainer > table > tbody > tr > td:last-of-type {
    border-right: none;
}

.CasksContainer > table > thead > tr > th, .CasksContainer > table > tbody > tr > td {
    font-size: 0.9em;
}


.CasksContainer > table > thead > tr > th,  .CasksContainer > table > tbody > tr > td{
    border: 1px solid #eaeaea;
    border-top: none;
    padding: 8px;
    text-align: center;
}


.CaskActionsButtonsContainer{
    position: absolute;
    right: 10px;
    top: 20px;
}

.CaskActionsButtonsContainer > button, .CaskActionsButtonsContainer > span > button {
    border-radius: 8px;
    border: none;
    margin-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}


.CaskRootContainer {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;

    position: absolute;
    left: calc(200px + 20px);
    top: calc(60px + 20px);
    width: calc(100% - 200px - (20px * 2));
}

.CaskRootContainer > label {
    font-weight: bold;
    font-size: 16px;
    padding: 20px;
    display: block;
    text-align: start;
}


.CaskContainerTitleSeparator {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
}

.CaskContainerSubtitle {
    margin-top: -35px;
    font-weight: normal!important;
    padding-bottom: 10px!important;
    font-size: 13px!important;
}

.MonoText {
    font-family: Courier, monospace;
}

.CasksContainer > table > tbody > tr > td > button {
    /* border: 1px solid gray; */
    border: none;
    background: #fd5454;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 8px;
    cursor: pointer;
}

.CaskActionButton {
    background: none!important;
}

.CaskSpannedColumn1 {
    border-right: none!important;
    width: 50px;
}

.CaskSpannedColumn2 {
    border-left: none!important;
}