@value colors: "../../globalcss/colors.module.css";
@value v-menu-color from colors;

@value sizes: "../../globalcss/sizes.module.css";
@value v-header-height, v-menu-width from sizes;

@value small, medium, large from "../../globalcss/breakpoints.module.css";


.MenuContainer {
    position: fixed;
    z-index: 200;
    width: v-menu-width;
    height: 100%;
    background-color: v-menu-color;
    left: 0;
    overflow: scroll;
    -webkit-box-shadow: 1px 0 3px 0 rgba(0,0,0,0.32);
    -moz-box-shadow: 1px 0 3px 0 rgba(0,0,0,0.32);
    box-shadow: 1px 0 3px 0 rgba(0,0,0,0.32);
    padding-bottom: 100px;
}

.HeaderLogo {
    height: calc(v-header-height * 0.7);
    margin-top: calc(v-header-height * 0.15);
    margin-bottom: calc(v-header-height * 0.15);
    width: 60%;
    /* left: 20%; */
    position: relative;
}

.MenuHeaderSeparator {
    width: 100%;
    height: 1px;
    position: absolute;
    /* top: calc(v-header-height - 1px); */
    top: calc(v-header-height - 0px);
    background-color: rgb(217, 217, 217);
}


@media small{
    .MenuContainer {
        display: none;
    }
}


.DisabledLink {
    cursor: not-allowed;
}

.MenuAppVersion {
    bottom: 15px;
    position: fixed;
    width: v-menu-width;
    left: 0;
    text-align: center;
    font-weight: bold;
}

.MenuContainer > a:hover, .MenuContainer > a:active, .MenuContainer > a:visited, .MenuContainer > a:focus  {
    text-decoration: none;
}


.MenuCopyright {
    font-weight: lighter;
}