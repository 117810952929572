@value colors: "../../globalcss/colors.module.css";

@value v-menu-selected-background-color, v-menu-selected-text-color,  v-submenu-selected-background-color, v-submenu-selected-text-color,  v-menu-unselected-background-color, v-menu-unselected-text-color, v-menu-not-selected-hover from colors;

.MenuItemContainer {
    width: calc(100% - 12px);
    font-weight: bold;
    color: v-menu-unselected-text-color;
    margin-left: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
}



.MenuItemActive {
    background-color: v-menu-selected-background-color;
}


/* Not selected section container */
.MenuItemContainer:not(.MenuItemActive)>a, .SubmenuItemContainer:not(.SubmenuItemActive)>a{
    color: v-menu-unselected-text-color;
}

.SubmenuItemContainer > a {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
}

.MenuItemContainer>a, .MenuItemContainerWithSubmenus {
    /* padding: 15px; */
}

.SubmenuItemContainer {
    margin: 10px;
}

.MenuItemContainer>a, .SubmenuItemContainer>a{
    text-decoration: none;
    height: 100%;
    display: block;
}

.MenuItemActive, .MenuItemActive > a, .SubmenuItemActive > a{
    color: v-menu-selected-text-color;
    background-color: v-menu-selected-background-color;
    font-weight: bold;
}

.MenuItemContainer:not(.MenuItemActive):hover {
    background-color: v-menu-not-selected-hover;
}

.MenuItemActive>a:hover, .MenuItemActive>a:active, .MenuItemActive>a:focus{
    text-decoration: none;
    color: v-menu-selected-text-color;
}



.MenuItemContainer:not(.MenuItemActive)>a:hover, .SubmenuItemContainer:not(.SubmenuItemActive)>a:hover{
    text-decoration: none;
}



.SubmenuItemContainer:not(.SubmenuItemActive) {
    color: v-menu-unselected-text-color;
}

.SubmenuItemActive {
    color: v-submenu-selected-text-color;
}

.SubmenuItemContainer>a:hover, .SubmenuItemActive>a:hover, .SubmenuItemActive>a:active {
    text-decoration: none;
    color: v-submenu-selected-text-color;
}

.SubmenuItemContainer>a:active, .SubmenuItemContainer>a:focus {
    text-decoration: none;
    color: v-menu-selected-text-color;
}

.SubmenuItemContainer:not(.SubmenuItemActive)>a:focus {
    color: v-menu-unselected-text-color;
}
